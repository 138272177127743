import Footer from './components/footer';
import Header from './components/header';
import Home from './components/home';

function App() {

  return (
   <>
   <Header />
   <Home />
   <Footer />
   </>
  );
}

export default App;
