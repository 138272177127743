import React from 'react';
import "./home.css";

import homeimg from "../assets/images/homeimg.png";
import wall from "../assets/images/wall3.jpg"
import floor from "../assets/images/floor5.png"
import kitchen from "../assets/images/kitchen.webp"
import room from "../assets/images/room.webp"

const Home = () => {
    return (
        <>
            <section className="home-page">

                <div className="home-img">
                    <img src={homeimg} alt="img" className="bgimg" />
                    <h1 className="home-heading">Our Neptune Stones and Tiles website <br /> is now on playstore</h1>
                    <button className="btn">Download Now</button>
                </div>
            </section>


            <section className="properties-section">
        <h1 className="property-heading">Category</h1>
        <div className="property-box">
          <div className="property-img-box">
            <img src={wall} alt="" height={230} width={300} />
            <p>Wall Tiles</p>
            <button className='img-btn'>Inquiry Now</button>
          </div>

          <div className="property-img-box">
          <img src={floor} alt="" height={230} width={300} />
            <p>Floor Tiles</p>
            <button className='img-btn'>Inquiry Now</button>
          </div>

          <div className="property-img-box">
          <img src={room} alt="" height={230} width={300} />
            <p>Room Tiles</p>
            <button className='img-btn'>Inquiry Now</button>
          </div>

          <div className="property-img-box">
          <img src={kitchen} alt="" height={230} width={300} />
            <p>Kitchen Tiles</p>
            <button className='img-btn'>Inquiry Now</button>
          </div>
        </div>


      </section>


        </>
    )
}

export default Home;