import React from 'react';

const Footer = () => {
  return (
    <>
      <section id="footer">
        <div className="container">
          <div className="row">
            
              <div className="col-sm-3">
                <div className="footer_1">
                  <h2>Tiles</h2>
                  <ul>
                    <li><a href="/">Wall Tiles</a></li>
                    <li><a href="/">Floor Tiles</a></li>
                    <li><a href="/">Bathroom Tiles</a></li>
                    <li><a href="/">Kitchen Tiles</a></li>
                    <li><a href="/">Room Tiles</a></li>
                  </ul>
                </div>
              </div>
              <div className="col-sm-3">
                <div className="footer_1">
                  <h2>Tiles by Type</h2>
                  <ul>
                    <li><a href="#">Vitrified Tiles</a></li>
                    <li><a href="#">Ceramic Tiles</a></li>
                    <li><a href="#">Porcelain Tiles</a></li>
                    <li><a href="#">Designer Tiles</a></li>
                    <li><a href="#">Cool Tiles</a></li>
                    <li><a href="#">Polished Vitrified Tiles</a></li>
                    <li><a href="#">Stain Resistant Tiles</a></li>
                  </ul>
                </div>
              </div>
              <div className="col-sm-3">
                <div className="footer_1">
                  <h2>Tiles by Colour</h2>
                  <ul>
                    <li><a href="#">White Tiles</a></li>
                    <li><a href="#">Black Tiles</a></li>
                    <li><a href="#">Grey Tiles</a></li>
                    <li><a href="#">Blue Tiles</a></li>
                    <li><a href="#">Red Tiles</a></li>
                    <li><a href="#">Orange Tiles</a></li>
                    <li><a href="#">Light Tiles</a></li>
                    <li><a href="#">Dark Tiles</a></li>
                    <li><a href="#">Purple Tiles</a></li>
                    <li><a href="#">Multi Colour Tiles</a></li>
                    <li><a href="#">Brown Tiles</a></li>
                  </ul>
                </div>
              </div>
              <div className="col-sm-3">
                <div className="footer_2">
                  <h2>Social Links</h2>
                  <ul className="social-network social-circle">
                    <li><a href="#" className="icoRss" title="Rss"><i class="fa-solid fa-rss"></i></a></li>
                    <li><a href="#" className="icoFacebook" title="Facebook"><i class="fa-brands fa-facebook-f"></i></a></li>
                    <li><a href="#" className="icoTwitter" title="Twitter"><i class="fa-brands fa-twitter"></i></a></li>
                    <li><a href="#" className="icoGoogle" title="Google +"><i class="fa-brands fa-google-plus-g"></i></a></li>
                    <li><a href="#" className="icoLinkedin" title="Linkedin"><i class="fa-brands fa-linkedin-in"></i></a></li>
                  </ul>
                  
                </div>
              </div>
          
          </div>
        </div>
      </section>
      <section id="footer_main" className="clearfix">
        <div className="col-sm-12">
          <div className="footer_main_1">
            <p className="text-center">© 2024 Neptune. All Rights Reserved. Design by<a href="/"> Neptune Stones and Tiles</a> </p>
          </div>
        </div>
      </section>
    </>
  );
}

export default Footer;
