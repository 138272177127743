import React, { Component } from 'react';
import "./header.css"
import { Link } from 'react-router-dom';
import logo from "../assets/images/logo4.png";

class Header extends Component {
  
  render() {
  return (
    <>
    <nav>
        <Link to="/"><img src={logo} alt="" height={60} className="logo" /></Link>
        <button className="header-btn">Download Now</button>
    </nav>
    </>
  )
}
}

export default Header